import Amplify, { API } from "aws-amplify";
import i18n from "i18next";
import compose from "lodash/fp/compose";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import NumberFormat from "react-number-format";
import { Link, withRouter } from "react-router-dom";
import ToolbarLogout from "../../../../../components/Toolbar/ToolbarLogout";
import Button from "../../../../../components/UI/Button/Button";
import Dropdown from "../../../../../components/UI/Dropdown/Dropdown";
import currenciesObject from "../../../../../data/currencies";
import sold_registration_mutation from "../../../../../mutations/BuySellRegistration/CreateSaleOrder";
import commission_query from "../../../../../queries/CashManager/Commission";
import exchange_rate_query from "../../../../../queries/CashManager/ExchangeRates";
import classes from "../../../CurrencyTrader/DossierModal/BuySell.module.scss";
import MarketRatesGraph from "../../../MarketRates/MarketRatesGraph/Graph";
import CustomerRegistration from "../CustomerRegistration";
import OnlineOfferComponent from "../OnlineOfferComponent";

import dropDownCurrencies from "../Data";
var dateFormat = require("dateformat");

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
  },
});

class SoldRegistration extends Component {
  currencies = currenciesObject[0];
  currency_trader_currencies = ["EUR", "USD", "GBP", "CHF"];

  constructor(props) {
    super();
    this.save_button = React.createRef();
    this.state = {
      currencyYouGet: props.location.state.currencyYouGet,
      currencyYouGive: props.location.state.currencyYouGive,
      currencyYouGetDsc: null,
      currencyYouGetSymbol: null,
      currencyYouGiveSymbol: null,
      current_user: props.location.state.current_user,
      otherOption: false,
      switch: false,
      sale_id: null,
      sold_amount: null,
      sale_price: null,
      completed_two: false,
      sales_rate: null,
      commission: 0,
      message: null,
      show_customer_registration: false,
      customer_state: null,
      cross_currency_active: props.location.state.cross_currency_active,
      customer_obligatory: false,
      transaction_status: "completed",
      button_active: true,
      sold_rate_ALL: 1,
      order_id: null,
      cash_or_bank: "cash",
    };
    this.urlRef = React.createRef();
  }

  onSave = (
    amountSoldCents,
    amountSoldCurrency,
    amountPaidCents,
    amountPaidCurrency,
    rate,
    commission,
    status,
    cashOrBank,
    companyRepresentative,
    firstName = "",
    lastName = "",
    address = "",
    cellphone = "",
    email = "",
    city = "",
    country = "",
    personalIdNumber = "",
    postalCode = "",
    typology = "",
    rea = ""
  ) => {
    var customer_created = false;
    this.setState({
      button_active: false,
    });

    let customer_attributes = this.state.show_customer_registration
      ? {
          firstName,
          lastName,
          address,
          cellphone,
          email,
          city,
          country,
          personalIdNumber,
          postalCode,
          typology,
          rea,
        }
      : null;

    this.props
      .sale_mutation({
        variables: {
          attributes: {
            amountSoldCents: amountSoldCents
              ? amountSoldCents.toFixed(2).toString()
              : null,
            amountSoldCurrency,
            amountPaidCents: amountPaidCents
              ? amountPaidCents.toFixed(2).toString()
              : null,
            amountPaidCurrency,
            status,
            commission,
            companyRepresentative,
            cashOrBank,
          },
          rate: rate.toString(),
          customer: customer_attributes,
        },
      })
      .then((order_registration_res) => {
        this.props.history.push({
          pathname: "/invoice",
          state: { orders: order_registration_res.data.createSaleOrder },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          button_active: true,
        });
        if (
          error.message ===
          "GraphQL error: You dont have enough currency balance to complete this transaction"
        ) {
          this.setState({
            message: i18n.t("YOU_DON_T_HAVE_ENOUGH_CURRENCY"),
          });
        } else if (
          error.message === "GraphQL error: You must define an exchange rate"
        ) {
          this.setState({
            message: i18n.t("MUST_DEFINE_AN_EXCHANGE_RATE"),
          });
        } else if (error.message === "GraphQL error: No balance provided") {
          this.setState({
            message: i18n.t("MAKE_SURE_YOU_HAVE_BALANCE"),
          });
        } else {
          this.setState({
            message: i18n.t("SOMETHING_WENT_WRONG"),
          });
        }
      });
  };

  onValueChange = async (values, input_type) => {
    await this.setState({
      [input_type]: parseFloat(values.value),
    });
    this.calculateSalePrice(
      parseFloat(values.value),
      this.state.sales_rate,
      this.state.commission,
      input_type
    );
  };

  async onSelectDropdown(selectedValue, id) {
    var value = selectedValue.slice(0, 3);
    await this.setState({ currencyYouGet: value });
    await this.setCurrencyData(value);
    const sales_rate = await this.fetchExchangeRates(value, "saleRate").then(
      (data) => {
        return data;
      }
    );
    this.setState({
      sales_rate,
    });

    if (sales_rate == 0) {
      this.setState({
        button_active: false,
      });
    } else {
      this.setState({
        button_active: true,
      });
    }
  }

  async setCurrencyData(state) {
    await this.setState({
      currencyYouGetDsc: this.currencies[this.state.currencyYouGet].description,
      currencyYouGetSymbol: this.currencies[this.state.currencyYouGet].symbol,
      currencyYouGiveSymbol: this.currencies[this.state.currencyYouGive].symbol,
    });
    if (state.currencyYouGet === "OTHER") {
      this.setState({ otherOption: true });
    }
  }

  showCustomerRegistration = () => {
    const customer_registration = !this.state.show_customer_registration;
    this.setState({
      show_customer_registration: customer_registration,
      transaction_status: customer_registration ? "pending" : "completed",
    });
  };

  fetchExchangeRates = async (currency, type) => {
    const result = await API.graphql({
      query: exchange_rate_query,
      variables: { currency: currency },
    });

    if (result.data.exchangeRates[0]) {
      return result.data.exchangeRates[0][type];
    } else {
      return 0;
    }
  };

  fetchCommission = async () => {
    const result = await API.graphql({
      query: commission_query,
    });

    if (result.data.commission) {
      return result.data.commission;
    } else {
      return 0;
    }
  };

  componentDidMount = async () => {
    const purchased_currency = this.props.location.state.currencyYouGive;
    const sold_currency = this.props.location.state.currencyYouGet;

    await this.fetchCommission().then((commission) => {
      this.setState({
        commission,
      });
    });

    const sold_rate_ALL = await this.fetchExchangeRates(
      sold_currency,
      "saleRate"
    ).then((data) => {
      return data;
    });

    if (purchased_currency !== "ALL") {
      const purchase_rate_ALL = await this.fetchExchangeRates(
        purchased_currency,
        "purchaseRate"
      ).then((data) => {
        return data;
      });
      const sales_rate = 1 / (purchase_rate_ALL / sold_rate_ALL);

      this.setState({
        sales_rate:
          sales_rate !== "NaN" && sales_rate !== "Infinity" ? sales_rate : 0,
        sold_rate_ALL: purchase_rate_ALL,
      });
    } else {
      this.setState({
        sales_rate: sold_rate_ALL == 0 ? null : sold_rate_ALL,
      });
    }

    if (sold_rate_ALL == 0) {
      this.setState({
        button_active: false,
      });
    } else {
      this.setState({
        button_active: true,
      });
    }

    this.setCurrencyData(this.state);
  };

  calculateSalePrice = (amount, sales_rate, commission, provided_input) => {
    let sale_price = this.state.sale_price;
    let sold_amount = this.state.sold_amount;

    if (provided_input === "sold_amount") {
      sale_price = amount * sales_rate;
      sale_price = sale_price + (sale_price * commission) / 100;
      sold_amount = amount;
    } else if (provided_input === "sale_price") {
      sold_amount = amount / sales_rate;
      if (sales_rate !== 0) {
        sold_amount = amount / (1 + commission / 100) / sales_rate;
      } else {
        sold_amount = null;
      }
      sale_price = amount;
    }

    let condition = false;
    let obligatory_condition = false;

    if (sale_price * this.state.sold_rate_ALL >= 100000) {
      condition = true;
      obligatory_condition = true;
    }

    this.setState({
      completed_two: true,
      sale_price: sale_price,
      sold_amount: sold_amount,
      show_customer_registration:
        this.state.show_customer_registration || condition,
      customer_obligatory: obligatory_condition,
      transaction_status:
        condition || this.state.show_customer_registration
          ? "pending"
          : "completed",
    });
  };

  take_child_data = (data) => {
    this.setState({
      customer_state: data,
    });
  };

  handleSwitch = () => {
    this.setState({
      switch: !this.state.switch,
      sold_amount: null,
      sale_price: null,
    });
  };

  handleRateInput = (e) => {
    this.setState({
      sales_rate: e.target.value,
    });
    const amount = this.state.switch
      ? this.state.sale_price
      : this.state.sold_amount;
    const provided_input = this.state.switch ? "sale_price" : "sold_amount";
    const commission = this.state.commission;
    this.calculateSalePrice(amount, e.target.value, commission, provided_input);
  };

  handleCommisionChange = (e) => {
    let commission;
    if (e.value === "") {
      this.setState({
        commission: null,
      });
      commission = 0;
    } else {
      this.setState({
        commission: parseFloat(e.value),
      });
      commission = parseFloat(e.value);
    }

    const amount = this.state.switch
      ? this.state.sales_price
      : this.state.sold_amount;
    const provided_input = this.state.switch ? "sale_price" : "sold_amount";
    const rate = this.state.sales_rate;
    this.calculateSalePrice(amount, rate, commission, provided_input);
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter" && this.state.button_active) {
      this.save_button.current.click();
    }
  };

  changeType = (e) => {
    this.setState({
      cash_or_bank: e.target.getAttribute("name"),
    });
  };

  render() {
    const url = `${process.env.REACT_APP_HOME_URL}/file/sales_invoice/${this.state.order_id}`;

    const getSalesInvoiceFile = () => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");

          link.setAttribute("href", url);
          link.setAttribute("download", "sales_invoice.xlsx.axlsx");
          link.click();
        });
    };

    const sale_registration_data = [
      this.state.sold_amount,
      this.state.currencyYouGet,
      this.state.sale_price,
      this.state.currencyYouGive,
      this.state.sales_rate,
      this.state.commission || 0,
      this.state.transaction_status,
      this.state.cash_or_bank,
      this.state.customer_state
        ? this.state.customer_state.personalIdNumber[0]
        : null,
    ];

    let customer_registration_data = [];

    if (this.state.customer_state !== null) {
      customer_registration_data = [
        this.state.customer_state.firstName,
        this.state.customer_state.lastName,
        this.state.customer_state.address,
        this.state.customer_state.cellphone,
        this.state.customer_state.email,
        this.state.customer_state.city,
        this.state.customer_state.country,
        [
          ...this.state.customer_state.company_rapresentatives,
          ...this.state.customer_state.personalIdNumber,
        ],
        this.state.customer_state.postalCode,
        this.state.customer_state.typology,
        this.state.customer_state.rea,
      ];
    }

    let label;
    let input_field;
    let suffix;
    let prefix;

    if (this.state.switch) {
      label = this.state.sold_amount;
      input_field = (
        <NumberFormat
          value={this.state.sale_price}
          suffix={this.state.currencyYouGiveSymbol}
          onKeyDown={this._handleKeyDown}
          thousandSeparator={true}
          className={classes.inputs}
          placeholder={"0.00 " + this.state.currencyYouGiveSymbol}
          onValueChange={(values, input) =>
            this.onValueChange(values, "sale_price")
          }
        />
      );
      suffix = null;
      prefix = this.state.currencyYouGetSymbol;
    } else {
      label = this.state.sale_price;
      input_field = (
        <NumberFormat
          autoFocus
          value={this.state.sold_amount}
          prefix={this.state.currencyYouGetSymbol}
          onKeyDown={this._handleKeyDown}
          thousandSeparator={true}
          className={classes.inputs}
          placeholder={this.state.currencyYouGetSymbol + " 0.00"}
          onValueChange={(values, input) =>
            this.onValueChange(values, "sold_amount")
          }
        />
      );
      suffix = this.state.currencyYouGiveSymbol;
      prefix = null;
    }

    return (
      <div>
        <ToolbarLogout />
        <div
          className={classes.newRegistration}
          style={{ justifyContent: "center" }}
        >
          {["EUR", "USD", "GBP", "CHF"].includes(this.state.currencyYouGet) ? (
            this.state.cross_currency_active ? (
              <div className={classes.graphComponent}>
                <div className={classes.Head}>
                  <p className={classes.TransactionText}>
                    {i18n.t("MARKET_RATES")}
                  </p>
                </div>

                {true ? (
                  <div className={classes.graphContainer}>
                    {/* <Graph panel={i18n.t("NEW_REGISTRATION1")} currencyType={this.state.currencyYouGet} showMaxIcon={false} showInWidget={true} /> */}
                    <MarketRatesGraph
                      panel={i18n.t("NEW_REGISTRATION1")}
                      currency={this.state.currencyYouGet}
                      showMaxIcon={false}
                      showInWidget={true}
                      width={480}
                    />
                  </div>
                ) : null}
              </div>
            ) : null
          ) : null}

          <div className={classes.Component} style={{ margin: "35px 25px" }}>
            <div className={classes.Head}>
              <p className={classes.TransactionText}>
                {i18n.t("NEW_REGISTRATION")}
              </p>
            </div>

            <div className={classes.container}>
              <div className={classes.firstPart}>
                <div className={classes.checkoutHeaderSell}>
                  <span
                    className={classes.someText}
                    name="cash"
                    style={{
                      color:
                        this.state.cash_or_bank == "cash" ? "#D39C3E" : null,
                    }}
                    onClick={(e) => this.changeType(e)}
                  >
                    CASH
                  </span>
                  <span>|</span>
                  <span
                    className={classes.someText}
                    name="bank"
                    style={{
                      color:
                        this.state.cash_or_bank == "bank" ? "#D39C3E" : null,
                    }}
                    onClick={(e) => this.changeType(e)}
                  >
                    BANK
                  </span>
                </div>
                <div className={classes.checkoutCurrencies}>
                  <div style={{ height: "80px" }}>
                    <p className={classes.someText}>
                      {i18n.t("FINISH_REGISTERING_YOUR_SALE_O")}
                    </p>

                    {this.state.otherOption ? (
                      <Dropdown
                        items={dropDownCurrencies}
                        onSelectDropdown={this.onSelectDropdown.bind(this)}
                      />
                    ) : (
                      <p className={classes.boldText}>
                        {" "}
                        {this.state.currencyYouGetDsc}{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className={classes.checkoutCurrencies}>
                <div
                  className={classes.checkoutCurrenciesContent}
                  style={{ position: "relative" }}
                >
                  <p className={classes.someText}>
                    {this.state.switch
                      ? i18n.t("WHAT_IS_THE_TOTAL_PRICE1")
                      : i18n.t("HOW_MUCH_ARE_YOU_SELLING")}
                  </p>
                  {input_field}
                  <i
                    className="fas fa-random"
                    onClick={() => this.handleSwitch()}
                    style={{
                      position: "absolute",
                      top: "40px",
                      left: "250px",
                      fontSize: "20px",
                      color: "#D39C3E",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>

              <div className={classes.checkoutCurrencies}>
                <div className={classes.checkoutCurrenciesContent}>
                  <p className={classes.someText}>
                    {i18n.t("THE_SALES_RATE_IS")}
                  </p>
                  <b className={classes.right_part}>
                    {this.state.current_user.isStoreAdmin ? (
                      <input
                        className={classes.inputs}
                        placeholder={"0.00"}
                        onKeyDown={this._handleKeyDown}
                        value={this.state.sales_rate || 0}
                        onChange={this.handleRateInput}
                      />
                    ) : (
                      parseFloat(this.state.sales_rate).toFixed(4)
                    )}
                  </b>
                </div>
              </div>

              <div className={classes.checkoutCurrencies}>
                <div className={classes.checkoutCurrenciesContent}>
                  <p className={classes.someText}>
                    {i18n.t("WHAT_IS_THE_COMMISSION")}
                  </p>
                  <div className={classes.right_part}>
                    <NumberFormat
                      className={classes.inputs}
                      suffix={"%"}
                      onKeyDown={this._handleKeyDown}
                      value={this.state.commission}
                      placeholder={"0%"}
                      onValueChange={(e) => this.handleCommisionChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div
                className={classes.checkoutCurrencies}
                style={{ height: "80px" }}
              >
                <div style={{ display: "flex" }}>
                  <i
                    className="fas fa-user-circle"
                    style={{
                      color: "#D39C3E",
                      fontSize: "23px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={
                      this.state.customer_obligatory
                        ? null
                        : () => this.showCustomerRegistration()
                    }
                  ></i>
                  <p className={classes.someText}>
                    {this.state.switch
                      ? i18n.t("AMOUNT_SOLD_IS")
                      : i18n.t("THE_TOTAL_PRICE_IS")}
                    <span style={{ marginLeft: "5px" }}>
                      {label ? (
                        <NumberFormat
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={prefix}
                          suffix={suffix}
                          value={
                            label === "0.0000" || !label
                              ? null
                              : label.toFixed(2)
                          }
                        />
                      ) : (
                        "--"
                      )}
                    </span>
                  </p>
                </div>
              </div>

              {this.state.show_customer_registration ? (
                <CustomerRegistration
                  sendData={this.take_child_data}
                  type={"sold"}
                />
              ) : null}
              {this.state.message ? (
                <p style={{ marginTop: "15px", color: "red" }}>
                  {this.state.message}
                </p>
              ) : null}
              <div className={classes.buttons}>
                <a href={url} ref={this.urlRef} />
                <Button
                  extraStyle={
                    classes[
                      !this.state.button_active ? "button_disabled" : null
                    ]
                  }
                  disabled={!this.state.button_active}
                  clicked={() =>
                    this.onSave(
                      ...sale_registration_data,
                      ...customer_registration_data
                    )
                  }
                >
                  {" "}
                  {i18n.t("SAVE")}{" "}
                </Button>
                <button
                  style={{ display: "none" }}
                  ref={this.save_button}
                  onClick={() =>
                    this.onSave(
                      ...sale_registration_data,
                      ...customer_registration_data
                    )
                  }
                ></button>
                <Link to="/dashboard">
                  {" "}
                  <Button extraStyle={classes.cancelButton}>
                    {" "}
                    {i18n.t("CANCEL")}{" "}
                  </Button>{" "}
                </Link>
              </div>
            </div>
          </div>

          {this.state.cross_currency_active &&
          this.currency_trader_currencies.includes(this.state.currencyYouGet) &&
          this.state.current_user.currencyTrader ? (
            <OnlineOfferComponent
              currency_symbol={this.state.currencyYouGetSymbol}
              currency={this.state.currencyYouGet}
              amount={this.state.sold_amount}
              cash_manager_rate={this.state.sales_rate}
              offer_order_type={"Purchase"}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(sold_registration_mutation, { name: "sale_mutation" })
)(withRouter(SoldRegistration));
