import { useQuery } from "@apollo/react-hooks";
import i18n from "i18next";
import React, { Component, useState } from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router";
import receive_client from "../../../../Client";
import FourDots from "../../../../components/UI/Icons/FourDots/FourDots";
import FilteredComponent from "../../../../containers/Modals/FilterModal/FilteredComponent";
import FilterModal from "../../../../containers/Modals/FilterModal/FilterModal";
import count_cash_manager from "../../../../queries/CashManager/CountCashManager";
import query from "../../../../queries/CashManager/GetOrders";
import users_query from "../../../../queries/LoggedUser";
import classes from "../CashManager.module.scss";

import getSymbolFromCurrency from "currency-symbol-map";

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      offset: 0,
      activePage: 1,
      max_rate: null,
      min_rate: null,
      min_paid: null,
      max_paid: null,
      min_value: null,
      max_value: null,
      min_date: null,
      max_date: null,
      currencies: null,
      sub_users: null,
      currency_objects: null,
      sub_users_objects: null,
      client_type: null,
      category: null,
      client: null,
      sorting_element: null,
      sorting_order: null,
      user_id: null,
    };
  }
  modalHandler = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  closeModal() {
    this.setState({ showModal: false });
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber,
      offset: (pageNumber - 1) * 7,
    });
  };

  getParent = (obj) => {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        obj[key] = null;
      }
    });

    let list_of_currencies = [];
    let list_of_sub_users = [];

    obj.currencies.forEach((currency) => {
      list_of_currencies.push(currency.value);
    });

    obj.sub_users.forEach((user) => {
      list_of_sub_users.push(user.value);
    });

    this.setState({
      activePage: 1,
      offset: 0,
      max_rate: obj.max_rate_value,
      min_rate: obj.min_rate_value,
      min_paid: obj.min_paid_value,
      max_paid: obj.max_paid_value,
      min_value: obj.min_value,
      max_value: obj.max_value,
      min_date: obj.min_date,
      max_date: obj.max_date,
      currencies: list_of_currencies.length > 0 ? list_of_currencies : null,
      sub_users: list_of_sub_users.length > 0 ? list_of_sub_users : null,
      currency_objects: obj.currencies,
      sub_users_objects: obj.sub_users,
      client_type: obj.client_type,
      category: obj.category,
      client: obj.client,
      sorting_element: obj.sorting_element,
      sorting_order: obj.sorting_order,
    });
  };

  onRemoveFilter = (element, value) => {
    if (element === "currency") {
      let currencies = this.state.currencies.filter((e) => e !== value);
      let currency_objects = this.state.currency_objects.filter(
        (e) => e.value !== value
      );
      this.setState({
        currencies: currencies.length > 0 ? currencies : null,
        currency_objects: currency_objects,
      });
    } else if (element === "sub_users") {
      let sub_users = this.state.sub_users.filter((e) => e !== value);
      let sub_users_objects = this.state.sub_users_objects.filter(
        (e) => e.value !== value
      );
      this.setState({
        sub_users: sub_users.length > 0 ? sub_users : null,
        sub_users_objects: sub_users_objects,
      });
    } else {
      this.setState({ [element]: null });
    }
  };

  componentDidMount = async () => {
    const client = receive_client();
    const result = await client.query({ query: users_query });
    this.setState({
      user_id: result.data.currentUser.id,
    });
  };

  render() {
    const obj = this.state;
    let filtering_string = "";
    Object.keys(obj).forEach(function (key) {
      if (
        obj[key] != null &&
        ![
          "showModal",
          "offset",
          "activePage",
          "currency_objects",
          "sorting_order",
          "sorting_element",
        ].includes(key)
      ) {
        filtering_string = filtering_string.concat(key + "=" + obj[key] + "&");
      }
    });

    filtering_string = filtering_string + "orders_typology=service_point";
    const url = `${process.env.REACT_APP_HOME_URL}/file/order_details?${filtering_string}`;

    const getOrderDetailsFile = () => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");

          link.setAttribute("href", url);
          link.setAttribute("download", "order_details.xls");
          link.click();
        });
    };

    var modal = null;
    if (this.state.showModal) {
      modal = (
        <FilterModal
          current_user={this.props.current_user}
          modal_open={this.modalHandler.bind(this)}
          get_parent={this.getParent}
          state={this.state}
          closeModal={this.closeModal.bind(this)}
        />
      );
    }

    var stati = this.state;
    var list_of_filters = [];

    Object.keys(stati).forEach((key) => {
      if (
        stati[key] !== null &&
        ![
          "showModal",
          "offset",
          "activePage",
          "currency_objects",
          "sub_users_objects",
          "user_id",
          "sorting_order",
          "sorting_element",
        ].includes(key)
      ) {
        list_of_filters.push(key);
      }
    });

    list_of_filters = list_of_filters.map((element) => {
      let currency_list = [];
      let sub_user_list = [];
      if (element === "currencies") {
        currency_list = this.state.currencies.map((currency) => {
          return (
            <FilteredComponent
              key={currency}
              property={"currency"}
              value={currency}
              onRemoveFilter={this.onRemoveFilter}
            />
          );
        });
        return currency_list;
      } else if (element === "sub_users") {
        sub_user_list = this.state.sub_users_objects.map((user) => {
          return (
            <FilteredComponent
              key={user}
              property={"sub-user"}
              value={user.name}
              onRemoveFilter={() =>
                this.onRemoveFilter("sub_users", user.value)
              }
            />
          );
        });
        return sub_user_list;
      } else {
        return (
          <FilteredComponent
            key={element}
            property={element.replace(/_/g, " ")}
            value={this.state[element]}
            onRemoveFilter={this.onRemoveFilter}
          />
        );
      }
    });

    return (
      <div
        style={{
          paddingLeft: "80px",
          paddingRight: "80px",
          paddingTop: "55px",
          paddingBottom: "55px",
          minHeight: "600px",
        }}
      >
        <div className="d-flex align-items-center">
          <div>
            <b style={{ fontSize: "25px" }}>{i18n.t("REGISTERED_ORDERS")}</b>
          </div>
          <div
            className="filterModalIcon"
            style={{
              position: "relative",
            }}
            onClick={() => {
              this.modalHandler();
            }}
          >
            <FourDots size={18} dots_size={7.37} /> {modal}
          </div>
          <i
            className="fas fa-share-square"
            style={{
              color: "rgb(211, 156, 62)",
              fontSize: "18px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            onClick={getOrderDetailsFile}
          ></i>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexFlow: "wrap",
            }}
          >
            {list_of_filters}
          </div>

          <Orders
            offset={this.state.offset}
            max_rate_value={this.state.max_rate}
            min_rate_value={this.state.min_rate}
            min_paid_value={this.state.min_paid}
            max_paid_value={this.state.max_paid}
            min_value={this.state.min_value}
            max_value={this.state.max_value}
            min_date={this.state.min_date}
            max_date={this.state.max_date}
            currencies={this.state.currencies}
            sub_users={this.state.sub_users}
            client_type={this.state.client_type}
            category={this.state.category}
            client={this.state.client}
            sorting_element={this.state.sorting_element}
            sorting_order={this.state.sorting_order}
          />
        </div>

        <div className={classes.order_pagination}>
          <PaginationComponent
            activePage={this.state.activePage}
            handlePageChange={this.handlePageChange}
            max_rate_value={this.state.max_rate}
            min_rate_value={this.state.min_rate}
            min_paid_value={this.state.min_paid}
            max_paid_value={this.state.max_paid}
            min_value={this.state.min_value}
            max_value={this.state.max_value}
            min_date={this.state.min_date}
            max_date={this.state.max_date}
            currencies={this.state.currencies}
            sub_users={this.state.sub_users}
            client_type={this.state.client_type}
            category={this.state.category}
            client={this.state.client}
            sorting_element={this.state.sorting_element}
            sorting_order={this.state.sorting_order}
          />
        </div>
      </div>
    );
  }
}
export default Sales;

class OrderCard extends Component {
  constructor(props) {
    super();
    this.state = {
      extraInfo: false,
      changed_itself: false,
    };
  }

  ExtraInfoHandler = () => {
    if (this.state.extraInfo === true) {
      this.setState({
        extraInfo: false,
        changed_itself: true,
      });
    } else {
      this.props.update_parent(this.props.index);
      this.setState({
        changed_itself: false,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.state.extraInfo !== this.props.extra_info &&
      !this.state.changed_itself
    ) {
      this.setState({
        extraInfo: this.props.extra_info,
      });
    }
  };

  openInvoice = () => {
    this.props.redirect_to_invoice(this.props.order_nr);
  };

  render() {
    if (this.state.extraInfo) {
      var extraInfoo = (
        <div className="d-flex">
          <div className={classes.order_card_block}></div>
          <div
            className={classes.order_card_details}
            style={{
              borderTop: "solid 1px #cccccc",
            }}
          >
            <div className={classes.order_card_extra}>
              <div
                className={classes.order_card_details_row}
                style={{
                  height: "30px",
                }}
              >
                <div className={classes.order_card_details_left}></div>
              </div>
              <div className={classes.order_card_details_twoColumns}>
                <div className={classes.order_card_details_leftColumn}>
                  <div
                    className={[
                      classes.order_card_extra_bold_text,
                      classes.order_card_borderBottom,
                    ].join(" ")}
                  >
                    {" "}
                    {i18n.t("ORDER_DETAILS")}
                  </div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("POSTED")}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    {this.props.date} {this.props.time}
                  </div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("ITEM1")}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    {this.props.purchase_or_sell}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    {i18n.t("AT")} {this.props.rate}/
                    {this.props.currency_symbol}1
                  </div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("ORDER_TOTAL")} {this.props.amount_paid}
                  </div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("EXECUTED_BY")} {this.props.user_name}
                  </div>
                </div>
                {this.props.typology ? (
                  <div className={classes.order_card_details_rightColumn}>
                    {this.props.ty}
                    <div
                      className={[
                        classes.order_card_extra_bold_text,
                        classes.order_card_borderBottom,
                      ].join(" ")}
                    >
                      {" "}
                      {i18n.t("CLIENT_DETAILS1")}
                    </div>

                    <div className={classes.order_card_extra_regular_text}>
                      {this.props.customer_name} {this.props.customer_surname}
                    </div>
                    {this.props.typology === "business" ? (
                      <div className={classes.order_card_extra_regular_text}>
                        {i18n.t("NUIS")} {this.props.rea}
                      </div>
                    ) : null}

                    <div className={classes.order_card_extra_bold_text}></div>
                    <div className={classes.order_card_extra_regular_text}>
                      {this.props.address}
                    </div>
                    <div className={classes.order_card_extra_regular_text}>
                      {this.props.postal_code} {this.props.city}{" "}
                      {this.props.country}
                    </div>

                    <div className={classes.order_card_extra_bold_text}></div>
                    <div className={classes.order_card_extra_regular_text}>
                      {this.props.email}
                    </div>
                    <div className={classes.order_card_extra_regular_text}>
                      {i18n.t("PHONE")} {this.props.phone}
                    </div>

                    <div className={classes.order_card_extra_bold_text}></div>
                    {this.props.typology === "business" ? (
                      <div className={classes.order_card_extra_bold_text}>
                        {i18n.t("REPRESENTATIVE")}
                      </div>
                    ) : null}

                    <div className={classes.order_card_extra_regular_text}>
                      {i18n.t("ID")} {this.props.personal_id}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={[
          classes.order_card_container,
          classes[this.props.state],
        ].join(" ")}
      >
        <div
          className={classes.order_card_header}
          onClick={this.ExtraInfoHandler}
        >
          <div className={classes.order_card_block}></div>
          <div className={classes.order_card_details}>
            <div
              className={classes.order_card_details_row}
              style={{
                height: "30px",
              }}
            >
              <div
                className={classes.order_card_details_left}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <div className={classes.order_card_logo}></div> */}
                {this.props.customer_name ? (
                  <span
                    className="fa-stack"
                    style={{
                      color:
                        this.props.state === "sales_cm"
                          ? "rgba(204, 204, 204, 0.28)"
                          : "#cccccc",
                      fontSize: "13px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i
                      className="fas fa-user fa-stack-1x fa-inverse"
                      style={{
                        fontSize: "16px",
                      }}
                    ></i>
                  </span>
                ) : (
                  <div className={classes.order_card_logo}></div>
                )}
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                  className={classes.order_card_small_text}
                >
                  {this.props.customer_name}
                </div>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                  className={classes.order_card_small_text}
                >
                  {this.props.customer_surname}
                </div>
              </div>
              <div className={classes.order_card_small_text}>
                {this.props.action === "Purchased"
                  ? i18n.t("PURCHASED2")
                  : i18n.t("SOLD2")}
                : {this.props.date}
              </div>
            </div>
            <div
              className={classes.order_card_details_row}
              style={{
                height: "30px",
              }}
            >
              <div
                className={classes.order_card_extra_bold_text}
                style={{
                  flex: 1,
                }}
              >
                {" "}
                {this.props.purchase_or_sell}{" "}
                {this.props.purchase_or_sell_currency}{" "}
              </div>
              <div className={classes.order_card_extra_bold_text}>
                {this.props.amount_paid}
              </div>
            </div>
            <div
              className={classes.order_card_details_row}
              style={{
                height: "20px",
              }}
            >
              <div
                className={classes.order_card_small_text}
                style={{
                  flex: 1,
                }}
              >
                {" "}
                {i18n.t("ORDER_NUMBER")} {this.props.order_nr}
              </div>
              <div className={classes.order_card_small_text}>
                {this.props.order_type === "Purchase"
                  ? i18n.t("PURCHASE_RATE1")
                  : i18n.t("SALES_RATE1")}
                : {this.props.rate}
              </div>
            </div>
          </div>
        </div>
        {this.state.extraInfo ? extraInfoo : null}
      </div>
    );
  }
}

function Orders({
  offset,
  max_rate_value,
  min_rate_value,
  min_paid_value,
  max_paid_value,
  min_value,
  max_value,
  min_date,
  max_date,
  currencies,
  sub_users,
  client_type,
  category,
  client,
  sorting_element,
  sorting_order,
  props,
}) {
  let [opened_child_index, setOpenedChildIndex] = useState(null);

  const { data, refetch } = useQuery(query, {
    variables: {
      attributes: {
        offset: offset,
        maxRateValue: max_rate_value,
        minRateValue: min_rate_value,
        minPaidValue: min_paid_value,
        maxPaidValue: max_paid_value,
        minValue: min_value,
        maxValue: max_value,
        minDate: min_date,
        maxDate: max_date,
        currency: currencies,
        clientType: client_type,
        category: category,
        client: client,
        sortingElement: sorting_element,
        sortingOrder: sorting_order,
        subUsers: sub_users,
      },
    },
  });
  const history = useHistory();
  const close_other_childs = (index) => {
    setOpenedChildIndex(index);
  };

  const redirect_to_invice = (order_nr) => {
    history.push({
      pathname: "/invoice",
      state: { order_nr },
    });
  };

  refetch();

  var listItems = null;

  if (data) {
    const result = data.orders;

    listItems = result.map((record, index) => {
      var dateFormat = require("dateformat");
      var order_date = dateFormat(record.datetime, "mmmm d, yyyy");
      var order_time = dateFormat(record.datetime, "HH:MM");

      let customer_first_name = null;
      let customer_last_name = null;
      let customer_address = null;
      let customer_city = null;
      let customer_state = null;
      let customer_postal_code = null;
      let customer_phone = null;
      let customer_email = null;
      let customer_rea = null;
      let customer_typology = null;

      if (record.customer) {
        customer_first_name = record.customer.firstName;
        customer_last_name = record.customer.lastName;
        customer_address = record.customer.address;
        customer_postal_code = record.customer.postalCode;
        customer_city = record.customer.city;
        customer_state = record.customer.country;
        customer_phone = record.customer.cellphone;
        customer_email = record.customer.email;
        customer_rea = record.customer.rea;
        customer_typology = record.customer.typology;
      }

      if (record.amountPurchasedCents) {
        return (
          <OrderCard
            key={index}
            index={index}
            order_id={record.id}
            user_name={record.portfolio.user.name}
            customer_name={customer_first_name}
            customer_surname={customer_last_name}
            order_type={"Purchase"}
            action={"Purchased"}
            amount_paid={record.amountPaidCents}
            purchase_or_sell={record.amountPurchasedCents}
            purchase_or_sell_currency={record.amountPurchasedCurrency}
            order_nr={record.number}
            currency_symbol={getSymbolFromCurrency(
              record.amountPurchasedCurrency
            )}
            date={order_date}
            time={order_time}
            rate={record.rate}
            personal_id={record.companyRepresentative}
            address={customer_address}
            postal_code={customer_postal_code}
            city={customer_city}
            state={customer_state}
            phone={customer_phone}
            email={customer_email}
            update_parent={close_other_childs}
            rea={customer_rea}
            typology={customer_typology}
            extra_info={opened_child_index === index ? true : false}
            country={customer_state}
            state={"purchases_cm"}
            redirect_to_invoice={(order_nr) => redirect_to_invice(order_nr)}
          />
        );
      } else {
        if (record.amountSoldCents) {
          return (
            <OrderCard
              key={index}
              index={index}
              order_id={record.id}
              user_name={record.portfolio.user.name}
              customer_name={customer_first_name}
              customer_surname={customer_last_name}
              order_type={"Sales"}
              action={"Sold"}
              amount_paid={record.amountPaidCents}
              purchase_or_sell={record.amountSoldCents}
              purchase_or_sell_currency={record.amountSoldCurrency}
              order_nr={record.number}
              currency_symbol={getSymbolFromCurrency(record.amountSoldCurrency)}
              date={order_date}
              time={order_time}
              rate={record.rate}
              personal_id={record.companyRepresentative}
              address={customer_address}
              postal_code={customer_postal_code}
              city={customer_city}
              state={customer_state}
              phone={customer_phone}
              email={customer_email}
              update_parent={close_other_childs}
              rea={customer_rea}
              typology={customer_typology}
              extra_info={opened_child_index === index ? true : false}
              state={"sales_cm"}
              redirect_to_invoice={(order_nr) => redirect_to_invice(order_nr)}
            />
          );
        }
      }
      return null;
    });
  }

  return <div>{listItems}</div>;
}

function PaginationComponent({
  activePage,
  handlePageChange,
  max_rate_value,
  min_rate_value,
  min_paid_value,
  max_paid_value,
  min_value,
  max_value,
  min_date,
  max_date,
  currencies,
  sub_users,
  client_type,
  category,
  client,
  sorting_element,
  sorting_order,
}) {
  const { data, refetch } = useQuery(count_cash_manager, {
    variables: {
      attributes: {
        maxRateValue: max_rate_value,
        minRateValue: min_rate_value,
        minPaidValue: min_paid_value,
        maxPaidValue: max_paid_value,
        minValue: min_value,
        maxValue: max_value,
        minDate: min_date,
        maxDate: max_date,
        currency: currencies,
        clientType: client_type,
        category: category,
        client: client,
        sortingElement: sorting_element,
        sortingOrder: sorting_order,
        subUsers: sub_users,
      },
    },
  });
  refetch();

  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={7}
      totalItemsCount={data ? data.countCashManager : null}
      pageRangeDisplayed={9}
      prevPageText={null}
      nextPageText={null}
      activeLinkClass={classes.activePage}
      itemClass={classes.numbers}
      linkClass={classes.numbers}
      onChange={handlePageChange}
    />
  );
}
