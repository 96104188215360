import i18n from "i18next";
import React, { Component } from "react";
import receive_client from "../../../../Client";
import equity_query from "../../../../queries/CashManager/Equity";
import classes from "../CashManager.module.scss";
import CurrencyBox from "./CurrencyBoxCheckout/CurrencyBoxCheckout.js";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equity: null,
      gain: null,
    };
  }

  componentDidMount = async () => {
    if (this.props.current_user.isStoreAdmin) {
      const client = receive_client();
      const equity = await client.query({ query: equity_query });
      this.setState({
        equity: equity.data.equity.equity,
        gain: equity.data.equity.gain,
      });
    }
  };

  render() {
    let filtering_string = `user_id=${this.props.current_user.id}&`;

    const currency_box = this.props.currencies.map((currency) => {
      if (currency.isChecked) {
        filtering_string = filtering_string.concat(
          "currencies[]=" +
            currency.value.split("(")[0].replace(/\s/g, "") +
            "&"
        );
        return (
          <CurrencyBox
            key={currency.value.split("(")[0].replace(/\s/g, "")}
            type={currency.value.split("(")[0].replace(/\s/g, "")}
            duration={this.props.duration}
            symbol={this.props.symbol}
            current_user={this.props.current_user}
          />
        );
      }
      return null;
    });

    filtering_string = filtering_string + "typology=service_point";

    const url = `${process.env.REACT_APP_HOME_URL}/file/daily_currency_details?${filtering_string}`;

    const getDailyCurrencyDetailsFile = () => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");

          link.setAttribute("href", url);
          link.setAttribute("download", "daily_currency_details.xls");
          link.click();
        });
    };

    return (
      <div
        style={{
          paddingLeft: "80px",
          paddingRight: "80px",
          paddingTop: "55px",
          paddingBottom: "55px",
          minHeight: "600px",
        }}
      >
        <div className="text-center">
          <div className={classes.summary_title}>
            {i18n.t("ACCOUNT_BALANCE")}{" "}
            <span className={classes.summary_store}>
              {i18n.t("AVAILABLE_IN_STORE")}
            </span>
          </div>
          <div className="hr_seperator"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            {this.props.current_user.isStoreAdmin ? (
              <div
                style={{
                  justifySelf: "flex-sart",
                  marginRight: "auto",
                  fontWeight: "bold",
                  display: "grid",
                }}
              >
                <div style={{ float: "left", textAlign: "start" }}>
                  {i18n.t("EQUITY")}: {this.state.equity}
                </div>
                <div style={{ float: "left", textAlign: "start" }}>
                  {i18n.t("GAIN_LOSS")}: {this.state.gain}
                </div>
              </div>
            ) : null}
            <i
              className="fas fa-list-ul"
              style={{
                color: "rgb(211, 156, 62)",
                fontSize: "18px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={getDailyCurrencyDetailsFile}
            ></i>
          </div>
        </div>
        {currency_box}
        <div className={classes.SummaryBottomTextContainer}>
          {i18n.t("YOUR_ACCOUNT_BALANCES_ARE_BASE")} <br />
          {i18n.t("MADE_IN_STORE_AND_REGISTERED_W")}
        </div>
      </div>
    );
  }
}

export default Summary;
